import Chef from "./chefAbi";
import {SwapPlatforms} from "@/config/swapPlatforms";

export const config = {
    name: "Singular",
    url: "https://singular.farm/farm",
    chef: {
        address: "0x9762Fe3ef5502dF432de41E7765b0ccC90E02e92",
        rewardTokenTicker: "SING",
        abi: Chef,
        rewardTokenFunction: "sing",
        pendingRewardsFunction: "pendingSing"
    },
    swap: SwapPlatforms.None
}

export default config;
